/* eslint-disable import/no-mutable-exports */
/* istanbul ignore file */
let API_BASE_URL = 'https://api-dev.scm.maersk.com/';
let MOP_APP_NAME = null;
let MOP_API_KEY = null;
const tenantId = '05d75c05-fa1a-42e7-9cf1-eb416c396f2d';
let applicationId = '2kM3GL1d+OzH47In4WkOCwMo/UMJtE/o3YILBDkd644=';
const clientId = '6b669637-6916-4bf9-9ea7-3c343536782c';
let redirectURL = `${window.location.origin}`;
let uamAccess = 'https://uam-dev.cs.scm.maersk.com/uamapp/accessrequest/';
let supersetUrl = 'https://realtime-analytics-preprod.maersk-digital.net'; // TODO: Add server superset url
let dashboardId = '5';
let engineeringMetricsDashboardId = '30';
const engineeringMetricsWorkstreamDashboardId = '7';
let trendsDashboardId = '8';
let miscAnalyticsDashboardId = '9';
let userAnalyticsDashboardId = '10';
const globalSearchDashboardId = '11';
let eventStatisticsDashboardId = '21'
const registerApiLink = 'https://confluence.maerskdev.net/display/NSCP/Registration+process';
const mprDashboardId = '19';

// HUBs URL
let scmHub = 'https://cdt.maersk.com/scmhub/';
let csHub = 'https://scm-dev.maersk.com/scp/cshub/';
let adminHub = 'https://scm-dev.maersk.com/admin-hub/'
let developerPortal = 'https://scm-dev.maersk.com/developer-hub/';
let c3 = 'https://c3-dev.cs.scm.maersk.com/';
const uiComponents = 'https://scm-dev.maersk.com/component-guide';
const maerskDesignSystem = 'https://designsystem.maersk.com/';
const knowMoreLink = 'https://teamsite.maerskgroup.com/teams/MaerskLeadLogistics/SitePages/New-Supply-Chain-Management-Platform.aspx';
const apiRegistration = 'https://maersk-tools.atlassian.net/wiki/spaces/NSCP/pages/175669256190/Registration+process';
const incidents = "https://dev.azure.com/SCMPlatform/SCM%20Backlog/_dashboards/dashboard/7a3a04e2-ac3b-4154-b997-85bef9d321dd"
const bstReports = 'https://dev.azure.com/SCMPlatform/Central%20Repo/_build?definitionId=756&view=ms.vss-pipelineanalytics-web.new-build-definition-pipeline-analytics-view-cardmetrics'
const qaPortal = 'https://qa-portal.scm.maersk.com/ui/?autologin=guest#general/dashboard/77'
let tracer = 'https://apiazewrmlns001tracing-fe.azurewebsites.net/'
const dataQualityReportId = '8ccb8628-7057-4345-b7ff-29619496bb1a'
const dataQualityGroupId = '1822568f-27fc-4ee5-9cf4-12f59791ab2d'
let sciConsumer = 'S/RqP06UnY0cMXq5diR6vg6PPaafs4mX3IFwtnhSSJk='
let realtimeAnalyticsApplicationKey = 'TeWhqPzRNgKWvC8bQPtD/w9mkR8QrpTQ2985P03/fTA='

switch (window.location.hostname) {
    case 'localhost':
        redirectURL = window.location.origin;
        supersetUrl = 'https://realtime-analytics-preprod.maersk-digital.net';
        dashboardId = '15';
        API_BASE_URL = 'https://api-preprod.scm.maersk.com/';
        csHub = 'https://scm-dev.maersk.com/scp/cshub/';
        adminHub = 'https://scm-dev.maersk.com/admin-hub/'
        developerPortal = 'https://scm-dev.maersk.com/developer-hub/';
        applicationId = 'h40bKEm6iqt3osmXaOD0Vn28fDPDLktTwYBcu5fiCLo=';
        break;
    case 'scm-preprod.maersk.com':
        API_BASE_URL = 'https://api-preprod.scm.maersk.com/';
        MOP_APP_NAME = 'SCM_Preprod_CentralHub';
        MOP_API_KEY = '2bD5UiRkXtupYomIJwNv5CUtlC1/D4RtSdw/7IIKfTPqoFToR0j+5tF4yCdayg==';
        uamAccess = 'https://uam-preprod.cs.scm.maersk.com/uamapp/accessrequest/';
        supersetUrl = 'https://realtime-analytics-preprod.maersk-digital.net';
        csHub = 'https://scm-preprod.maersk.com/scp/cshub/';
        c3 = 'https://c3-preprod.cs.scm.maersk.com/';
        scmHub = 'https://demo.maersk.com/scmhub/';
        adminHub = 'https://scm-preprod.maersk.com/admin-hub/'
        applicationId = 'h40bKEm6iqt3osmXaOD0Vn28fDPDLktTwYBcu5fiCLo=';
        dashboardId = '9';
        trendsDashboardId = '0';
        miscAnalyticsDashboardId = '0';
        userAnalyticsDashboardId = '0';
        eventStatisticsDashboardId = '21'
        engineeringMetricsDashboardId = '30'
        break;
    case 'scm.maersk.com':
        API_BASE_URL = 'https://api.scm.maersk.com/';
        MOP_APP_NAME = 'SCM_Prod_CentralHub';
        MOP_API_KEY = '2bD5UiRkXtqgaIKNdFQ+ukf0I1iwo9DWFS4QAg0QgTL+9bPKvQj0hbT2P9YjJQ==';
        uamAccess = 'https://uam.cs.scm.maersk.com/uamapp/accessrequest/';
        supersetUrl = 'https://realtime-analytics.scm.maersk.com';
        csHub = 'https://scm.maersk.com/scp/cshub/';
        c3 = 'https://c3.cs.scm.maersk.com/';
        scmHub = 'https://beta.maersk.com/scmhub/';
        adminHub = 'https://scm.maersk.com/admin-hub/'
        applicationId = 'GhPLzBCtk08H+zveA4DYUAedwdaVhxLz/OjrO9PFE4A=';
        trendsDashboardId = '8';
        miscAnalyticsDashboardId = '9';
        userAnalyticsDashboardId = '10';
        tracer = 'https://apiazewpmlns001tracing-fe.azurewebsites.net/'
        sciConsumer = 'GXR3kot7XFE/V2evx36zoI0Tz1/ojSJinJUN+Vknyys='
        eventStatisticsDashboardId = '18';
        engineeringMetricsDashboardId = '21'
        break;
    default:
}
export {
    API_BASE_URL,
    MOP_APP_NAME,
    MOP_API_KEY,
    tenantId,
    applicationId,
    clientId,
    redirectURL,
    uamAccess,
    scmHub,
    csHub,
    adminHub,
    developerPortal,
    supersetUrl,
    dashboardId,
    c3,
    registerApiLink,
    engineeringMetricsDashboardId,
    engineeringMetricsWorkstreamDashboardId,
    trendsDashboardId,
    userAnalyticsDashboardId,
    miscAnalyticsDashboardId,
    globalSearchDashboardId,
    uiComponents,
    maerskDesignSystem,
    knowMoreLink,
    apiRegistration,
    incidents,
    bstReports,
    qaPortal,
    tracer,
    dataQualityReportId,
    dataQualityGroupId,
    sciConsumer,
    realtimeAnalyticsApplicationKey,
    eventStatisticsDashboardId,
    mprDashboardId
};
